<template>
  <validation-provider
    :name="name"
    :rules="{ required: isRequired, cep: 9 }"
    v-slot="{ errors }"
  >
    <v-text-field
      v-model.lazy="inputModel"
      v-mask="masks.cep"
      :error-messages="customError || errors[0]"
      type="text"
      v-bind="{ ...attrs }"
    ></v-text-field>
  </validation-provider>
</template>
<script>
  import FieldMixin from "@/modules/core/mixins/fieldMixin";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { extend } from "vee-validate";
  import { min } from "vee-validate/dist/rules";

  extend("cep", { ...min, message: "Esse CEP é inválido" });

  export default {
    props: {
      customError: {
        type: String,
        require: false,
        default: () => undefined
      },
      value: {
        type: null
      }
    },
    data: () => ({
      innerValue: ""
    }),
    mixins: [MasksMixin, FieldMixin],
    computed: {
      inputModel: {
        get() {
          if (!this.innerValue && this.value) {
            return this.value;
          }
          return this.innerValue;
        },
        set(newVal) {
          this.innerValue = newVal;
          this.$emit("input", newVal.replace("-", ""));
        }
      }
    }
  };
</script>
<style></style>
