<template>
  <v-dialog v-model="state.dialog" width="588">
    <validation-observer>
      <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
        <v-card>
          <v-card-title>Adicionar PEV</v-card-title>
          <div class="mt-4 mx-5">
            <div class="d-flex flex-wrap">
              <div
                class="flex-grow-0"
                :class="{
                  'pr-5': !$isMobile,
                  fill: $isMobile,
                  'image-container': !$isMobile
                }"
              >
                <h3>Foto</h3>
                <image-upload @onUploadComplete="setPartnerPhoto" />
              </div>
              <div class="flex-grow-1" :class="{ 'mt-3': $isMobile }">
                <h3 class="mb-2">Nome do PEV</h3>
                <field
                  v-model="pevForm.name"
                  type="genericText"
                  label="Nome"
                  name="name"
                  :isRequired="true"
                />

                <h3 class="mb-2 mt-n2">Endereço</h3>
                <field
                  v-model="pevForm.address.zipCode"
                  type="cep"
                  label="CEP"
                  name="CEP"
                  :isRequired="true"
                />
              </div>
            </div>

            <p v-if="!!pevForm.address.address" class="address-info">
              {{ address }}
            </p>

            <v-row>
              <v-col cols="12" lg="4">
                <field
                  v-model="pevForm.address.number"
                  type="genericText"
                  label="Número"
                  name="number"
                  :isRequired="true"
                />
              </v-col>
              <v-col cols="12" lg="8">
                <field
                  v-model="pevForm.address.complement"
                  type="genericText"
                  label="Complemento"
                  name="complement"
                  :isRequired="false"
                />
              </v-col>
            </v-row>
            <h3>Informe os dias e horários de funcionamento</h3>
            <v-row>
              <v-col cols="12">
                <field
                  v-model="pevForm.businessHours"
                  type="textarea"
                  label="Descrição"
                  placeholder="Ex: Segunda às 8h - 18h"
                  name="businessHours"
                  max="250"
                  :isRequired="true"
                />
                <v-row
                  class="d-flex justify-end mr-4"
                  style="margin-top: -16px"
                >
                  <span>{{ pevForm.businessHours.length }}/250</span>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-card-actions class="d-flex flex-wrap justify-space-between">
            <v-btn
              class="mt-1 cancel-button black-3--text"
              color="#ffffff"
              :block="$isMobile"
              @click="state.dialog = false"
              >Cancelar</v-btn
            >
            <v-btn
              type="submit"
              :disabled="invalid"
              :block="$isMobile"
              color="secondary-color-1"
              class="ma-0 mt-1 next-step-button white--text"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationObserver } from "vee-validate";
  import { parseAdressSingleLine } from "@/modules/core/helpers/addressHelpers";

  import Field from "@/modules/core/views/components/Field";
  import ImageUpload from "@/modules/core/views/components/ImageUpload";

  export default {
    props: {
      partner: {
        type: Object,
        required: false
      },
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      ImageUpload,
      Field,
      ValidationObserver
    },

    // created() {
    //   this.pevForm.partner = this.partner;
    // },

    data: () => ({
      pevForm: {
        photo: null,
        businessHours: "",
        address: {
          zipCode: "",
          city: "",
          neighborhood: "",
          number: "",
          address: "",
          state: {}
        }
      }
    }),
    watch: {
      "pevForm.address.zipCode"(value) {
        if (value.length >= 8) {
          this.setZipCode(value);
        }
      }
    },
    computed: {
      ...mapGetters({
        states: "crc/getStates"
      }),
      address() {
        return parseAdressSingleLine(this.pevForm.address);
      }
    },
    methods: {
      ...mapActions("crc", ["getAddressByZipCode"]),
      ...mapActions(["toggleSnackbar"]),
      submitForm() {
        this.$emit("submit", this.pevForm);
      },
      async setPartnerPhoto(file) {
        this.pevForm.photo = file;
      },
      async setZipCode(value) {
        const data = await this.getAddressByZipCode(value);

        if (data.erro) {
          this.pevForm.address.address = "";
          this.pevForm.address.neighborhood = "";
          this.pevForm.address.city = "";
          return this.toggleSnackbar({
            text: "CEP Não encontrado",
            type: "error"
          });
        }

        if (data) {
          this.pevForm.address.address = data.logradouro;
          this.pevForm.address.neighborhood = data.bairro;
          this.pevForm.address.city = data.localidade;
          Object.keys(this.states).map(key => {
            if (this.states[key].uf.toLowerCase() === data.uf.toLowerCase()) {
              this.pevForm.address.state = this.states[key];
            }
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .address-info {
    background: #f3f6f9;
    padding: 8px;
    color: #455a64;
    font-weight: bold;
    font-size: 14px;
    border-radius: 3px;
  }

  .image-container {
    width: 172px;
  }
</style>
