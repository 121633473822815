import { pickBy, identity } from "lodash";
import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Esse campo é obrigatório"
});

export default {
  props: {
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: () => false
    },
    id: {
      type: null
    },
    ignoreRequiredLabel: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    attrs() {
      return pickBy(
        {
          outlined: true,
          color: this.$vuetify.theme.themes.light["secondary-color-1"],
          label: this.getLabel(),
          name: this.name,
          id: this.id,
          required: this.isRequired
        },
        identity
      );
    }
  },

  methods: {
    getLabel() {
      if (!this.label) {
        return null;
      }

      return this.ignoreRequiredLabel
        ? this.label
        : this.label.concat(`${this.isRequired ? " *" : ""}`);
    }
  }
};
