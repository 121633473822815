export const hasKeys = (obj, keys) => {
  if (!keys || !Array.isArray(keys)) {
    throw new Error("Invalid keys param, expected array of strings");
  }

  const objKeys = Object.keys(obj);

  return keys.every(key => {
    const hasKey = objKeys.includes(key);
    return hasKey;
  });
};
