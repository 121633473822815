<template>
  <div>
    <v-dialog v-model="state.dialog" :persistent="true" width="488">
      <v-card class="modal">
        <v-card-title class="modal-title">{{ title }}</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <div class="pt-5">
            <p>Tem certeza que deseja realizar esta ação?</p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            class="cancel-button white--text"
            color="error"
            @click="state.dialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            type="button"
            @click="confirm"
            text
            class="next-step-button error--text"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { hasKeys } from "@/modules/core/helpers/objectHelpers";

  export default {
    name: "DialogTogglePEVSituation",

    props: {
      state: {
        type: Object,
        required: true,
        validator: obj => hasKeys(obj, ["dialog"])
      },
      title: {
        type: String,
        required: true
      }
    },

    methods: {
      confirm() {
        this.state.dialog = false;
        this.$emit("confirmChangeSituation");
      }
    }
  };
</script>

<style></style>
