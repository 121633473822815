<template>
  <v-row class="ma-0 mt-2" align="center" justify="start">
    <v-icon size="20" color="black-4--text" class="icon-color">{{
      icon
    }}</v-icon>
    <p class="ma-0 ml-4 black-1--text">
      {{ text }}
    </p>
  </v-row>
</template>

<script>
  export default {
    props: ["icon", "text"]
  };
</script>
