<template>
  <v-btn
    v-on="$listeners"
    color="secondary-color-1"
    height="56"
    depressed
    class="white--text flex-column"
  >
    <v-icon>mdi-download</v-icon>
    <span>PDF</span>
  </v-btn>
</template>

<script>
  export default {
    name: "BtnPdfDownload"
  };
</script>

<style></style>
