export const phoneTypes = {
  HOME_PHONE: {
    label: "Residencial",
    value: "RE"
  },
  COMMERTIAL_PHONE: {
    label: "Comercial",
    value: "CO"
  },
  CELL_PHONE: {
    label: "Celular",
    value: "CE"
  }
};

export const getPhoneLabelByValue = type => {
  const phoneTypesArray = Object.values(phoneTypes);

  return phoneTypesArray.find(phoneType => phoneType.value === type).label;
};
