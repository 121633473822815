<template>
  <v-img v-bind="$attrs" v-on="$listeners" :src="photoSrc"></v-img>
</template>

<script>
  import ImageMixin from "@/modules/core/mixins/imageMixin";
  import image from "@/assets/no_photo.svg";

  export default {
    name: "CoreImage",

    mixins: [ImageMixin],

    methods: {
      getDefaultImage() {
        return image;
      }
    }
  };
</script>

<style scoped></style>
