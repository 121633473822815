var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'password')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors[0],"type":"password"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,29245193)}):_vm._e(),(_vm.type === 'weight')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors[0],"type":"number"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,10582613)}):_vm._e(),(_vm.type === 'email')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors[0],"type":"email"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,3670900218)}):_vm._e(),(_vm.type === 'genericText')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors[0],"type":"text"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,2341676235)}):_vm._e(),(_vm.type === 'number')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{
      required: _vm.isRequired,
      max_value: _vm.maxValue,
      min_value: _vm.minValue
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":_vm.customError || errors[0],"type":"number"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,3907236318)}):_vm._e(),(_vm.type === 'textarea')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-textarea',_vm._g(_vm._b({attrs:{"error-messages":errors[0],"type":"text"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-textarea',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,2608838347)}):_vm._e(),(_vm.type === 'cpf')?_c('cpf-field',{attrs:{"label":_vm.label,"name":_vm.name,"isRequired":_vm.isRequired},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}):_vm._e(),(_vm.type === 'cnpj')?_c('cnpj-field',{attrs:{"label":_vm.label,"name":_vm.name,"isRequired":_vm.isRequired},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}):_vm._e(),(_vm.type === 'rg')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.rg),expression:"masks.rg"}],attrs:{"error-messages":errors[0],"type":"text"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,193183826)}):_vm._e(),(_vm.type === 'cnh')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.masks.cnh),expression:"masks.cnh"}],attrs:{"error-messages":errors[0],"type":"text"},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,1502573042)}):_vm._e(),(_vm.type === 'select')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-select',_vm._g(_vm._b({attrs:{"error-messages":errors[0]},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-select',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,1301195192)}):_vm._e(),(_vm.type === 'tel')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"error-messages":errors[0],"maxlength":"15","maxlen":"","type":"tel"},on:{"input":_vm.inputPhone},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,3786717338)}):_vm._e(),(_vm.type === 'cep')?_c('cep-field',{attrs:{"label":_vm.label,"name":_vm.name,"isRequired":_vm.isRequired,"customError":_vm.customError},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}):_vm._e(),(_vm.type === 'calendar')?_c('validation-provider',{attrs:{"name":_vm.name,"rules":{ required: _vm.isRequired }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors[0],"append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'v-text-field',Object.assign({}, _vm.$attrs, _vm.attrs),false),_vm.$listeners))]}}],null,false,1860920201)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }