<template>
  <v-card class="fill-height d-flex flex-column justify-space-between">
    <div>
      <v-card-title>
        <div class="d-flex flex-wrap justify-space-between fill">
          <h3>Contrato</h3>
          <v-btn
            v-if="canAddContract"
            color="secondary-color-1"
            class="next-step-button white--text"
            :block="$isMobile"
            @click="handleNewContract"
          >
            <v-icon>mdi-plus</v-icon>&nbsp;
            <span class="mt-1">Novo Contrato</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>

      <v-data-table :headers="headers" :items="contracts" hide-default-footer>
        <template v-slot:[`item.download`]="{ item }">
          <v-icon @click="downloadContract(item)">mdi-download</v-icon>
        </template>
      </v-data-table>

      <dialog-new-contract
        :state="dialogNewContractState"
        :key="`new-contract-${dialogNewContractKey}`"
        @submit="handleSubmitNewContract"
      />
    </div>

    <div v-if="hasContracts">
      <pagination
        :showItemsPerPage="false"
        :itemsPerPage="tableInfo.itemsPerPage"
        :currentPage="tableInfo.currentPage"
        :totalPages="tableInfo.totalPages"
        @currentPageChanged="handleCurrentPageChange($event)"
      />
    </div>
  </v-card>
</template>

<script>
  import Pagination from "@/modules/core/views/components/Pagination";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import DownloadMixin from "@/modules/core/mixins/downloadMixin";
  import DialogNewContract from "@/modules/partner/views/components/DialogNewContract";

  import { mapActions, mapGetters } from "vuex";
  import { parseISO } from "date-fns";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { profiles } from "@/modules/core/enums/profiles.enum";

  export default {
    name: "PartnerContracts",

    components: {
      Pagination,
      DialogNewContract
    },

    mixins: [MasksMixin, TableHandler, DownloadMixin],

    data: () => ({
      headers: [
        { text: "Início", value: "beginAt" },
        { text: "Término", value: "endAt" },
        { value: "download", sortable: false }
      ],

      dialogNewContractKey: 1,
      dialogNewContractState: {
        dialog: false
      },

      contracts: [],

      canAddContract: false
    }),

    watch: {
      "dialogNewContractState.dialog": function(newVal) {
        if (!newVal) {
          this.dialogNewContractKey++;
        }
      }
    },

    created() {
      this.tableInfo.limit = 8;
    },

    mounted() {
      this.initPermissions();
      this.fetchContracts();
    },

    computed: {
      ...mapGetters({
        partner: "partner/getPartnerView",
        lastContract: "partner/getLastPartnerContract",
        user: "user/getUser"
      }),

      hasContracts() {
        return !!this.contracts?.length;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("partner", ["createContract", "getPartnerContracts"]),

      initPermissions() {
        const usersCanAddContract = [
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ];
        this.canAddContract = usersCanAddContract.includes(this.user.role.id);
      },

      downloadContract(contract) {
        if (!contract?.file?.hash) {
          return;
        }
        this.executeDownload(contract.file.hash);
      },

      updateData() {
        this.fetchContracts();
      },

      async fetchContracts() {
        try {
          const id = parseInt(this.$route.params.id, 10);
          const { page, limit } = this.getPayloadData();

          const { data, meta } = await this.getPartnerContracts({
            id,
            params: {
              page,
              limit
            }
          });

          this.contracts = this.formatContracts(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatContracts(contracts) {
        return contracts.map(contract => {
          const beginAtISOFormat = parseISO(contract.beginAt);
          const endAtISOFormat = parseISO(contract.endAt);

          return {
            ...contract,
            beginAt: this.parseDate(beginAtISOFormat, "dd MMM yyyy"),
            endAt: this.parseDate(endAtISOFormat, "dd MMM yyyy")
          };
        });
      },

      handleNewContract() {
        this.dialogNewContractState.dialog = true;
      },

      async handleSubmitNewContract(payload) {
        try {
          const newContractPayload = this.mountNewContractPayload(payload);
          const response = await this.createContract({
            id: this.partner.id,
            data: newContractPayload
          });
          this.toggleSnackbar({
            text: response?.data?.message ?? "Contrato registrado com sucesso",
            type: "success"
          });
          this.$emit("updated");
          this.updateData();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      mountNewContractPayload(payload) {
        return {
          beginAt: payload.beginAt,
          endAt: payload.endAt,
          file: {
            id: payload.file.id
          },
          crc: {
            id: this.lastContract.crc.id
          }
        };
      }
    }
  };
</script>

<style></style>
