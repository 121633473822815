<template>
  <v-card v-if="partner.id">
    <v-card-title class="d-flex justify-space-between">
      <h3>{{ partner.companyName }}</h3>
      <pencil-btn v-if="canEditPartner" @action="handleEditPartner" />
    </v-card-title>

    <v-card-text>
      <v-row v-if="partner.cnpj" no-gutters>
        <p class="ma-0 black-1--text">
          {{ partner.cnpj | VMask(masks.cnpj) }}
        </p>
      </v-row>
      <v-row no-gutters>
        <partner-situation-chip :text="partner.situation" />
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-title>
      <h3>CRC</h3>
    </v-card-title>

    <v-card-text>
      <partner-list-item
        icon="mdi-store"
        :text="activeContractCRC.description"
      />
    </v-card-text>

    <v-divider />

    <v-card-title class="d-flex justify-space-between">
      <h3>Responsável</h3>
      <pencil-btn v-if="canEditPartner" @action="handleEditAgent" />
    </v-card-title>

    <v-card-text>
      <partner-list-item icon="mdi-account" :text="partner.agent.name" />
      <partner-list-item
        icon="mdi-email"
        :text="partner.agent.email ? partner.agent.email : 'não informado'"
      />
      <partner-list-item icon="mdi-phone" :text="partner.agent.phonesNumber" />
    </v-card-text>
    <v-divider />

    <v-card-title>
      <h3>Contrato</h3>
    </v-card-title>

    <v-card-text>
      <partner-list-item icon="mdi-calendar" :text="activeContractDate" />
    </v-card-text>

    <dialog-edit-partner-agent
      :state="dialogEditPartnerAgentState"
      :key="`edit-agent-${dialogEditPartnerAgentKey}`"
      @submit="handleSubmitEditAgent"
    />

    <dialog-edit-partner
      :partner="partner"
      :state="dialogEditPartnerState"
      :key="`edit-partner-${dialogEditPartnerKey}`"
      @submit="handleSubmitEditPartner"
    />
  </v-card>
</template>

<script>
  import PartnerSituationChip from "@/modules/partner/views/components/PartnerSituationChip";
  import PartnerListItem from "@/modules/partner/views/components/PartnerListItem";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import PencilBtn from "@/modules/core/views/components/PencilBtn";
  import DialogEditPartnerAgent from "@/modules/partner/views/components/DialogEditPartnerAgent";
  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";
  import { profiles } from "@/modules/core/enums/profiles.enum";
  import DialogEditPartner from "@/modules/partner/views/components/DialogEditPartner";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "PartnerInfo",

    components: {
      PartnerSituationChip,
      PartnerListItem,
      PencilBtn,
      DialogEditPartnerAgent,
      DialogEditPartner
    },

    mixins: [MasksMixin],

    data: () => ({
      dialogEditPartnerAgentKey: 1,
      dialogEditPartnerAgentState: {
        dialog: false,
        form: {
          name: undefined,
          email: undefined,
          phones: []
        }
      },
      dialogEditPartnerKey: 1,
      dialogEditPartnerState: {
        dialog: false
      },
      canEditPartner: false
    }),

    mounted() {
      this.initPermissions();
    },

    watch: {
      "dialogEditPartnerAgentState.dialog": function(newVal) {
        if (!newVal) {
          this.dialogEditPartnerAgentKey++;
        }
      },

      "dialogEditPartnerState.dialog": function(newVal) {
        if (!newVal) {
          this.dialogEditPartnerKey++;
        }
      }
    },

    computed: {
      ...mapGetters({
        partner: "partner/getPartnerView",
        lastContract: "partner/getLastPartnerContract",
        user: "user/getUser"
      }),

      activeContractCRC() {
        return this.lastContract ? this.lastContract.crc : {};
      },

      activeContractDate() {
        if (!this.lastContract) {
          return "";
        }
        return `${this.lastContract.beginAt} até ${this.lastContract.endAt}`;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("partner", ["updatePartner"]),

      initPermissions() {
        const userscanEditPartner = [
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ];

        this.canEditPartner = userscanEditPartner.includes(this.user.role.id);
      },

      handleEditAgent() {
        this.dialogEditPartnerAgentState = {
          dialog: true,
          form: { ...this.partner.agent }
        };
      },

      handleEditPartner() {
        this.dialogEditPartnerState = {
          dialog: true
        };
      },

      mountEditAgentPayload(payload) {
        return {
          ...payload,
          phones: preparePhonesToSubmit(payload.phones)
        };
      },

      async handleSubmitEditAgent(formData) {
        const formatedData = this.mountEditAgentPayload(formData);

        await this.handleUpdatePartner(
          { agent: formatedData },
          "Responsável alterado com sucesso"
        );
      },

      async handleSubmitEditPartner(formData) {
        await this.handleUpdatePartner(
          formData,
          "Parceiro atualizado com sucesso"
        );
      },

      async handleUpdatePartner(body, successMessage) {
        try {
          await this.updatePartner({
            id: this.partner.id,
            body
          });

          this.toggleSnackbar({
            text: successMessage,
            type: "success"
          });

          this.$emit("updated");
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      }
    }
  };
</script>
<style></style>
