<template>
  <v-container>
    <v-row>
      <v-col align-self="center" v-bind="dinamicAttributesGrid">
        <v-container class="container-pagination">
          <v-pagination
            :value="currentPage"
            :length="totalPages"
            @input="$emit('currentPageChanged', $event)"
          ></v-pagination>
        </v-container>
      </v-col>
      <v-col
        v-if="showItemsPerPage"
        align-self="center"
        cols="12"
        offset-md="2"
        md="2"
      >
        <v-select
          outlined
          color="secondary-color-1"
          label="Itens por página"
          :items="[10, 25, 50, 100]"
          :value="itemsPerPage"
          hide-details
          @input="$emit('itemsPerPageChanged', $event)"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },

      itemsPerPage: {
        type: Number,
        required: true
      },

      totalPages: {
        type: Number,
        required: true
      },

      showItemsPerPage: {
        type: Boolean,
        default: true
      }
    },

    data: () => ({
      dinamicAttributesGrid: {}
    }),

    mounted() {
      this.initialize();
    },

    methods: {
      initialize() {
        if (this.showItemsPerPage) {
          this.dinamicAttributesGrid = {
            cols: "12",
            "offset-md": "4",
            md: "4"
          };
        }
      }
    }
  };
</script>
<style scoped>
  .container-pagination {
    max-width: 80vw;
    min-width: 320px;
  }
</style>
