<template>
  <div>
    <p :class="`ma-0 ${color}--text`">
      {{ firstLine }}
    </p>

    <p :class="`ma-0 ${color}--text`">
      {{ secondLine }}
    </p>
  </div>
</template>

<script>
  export default {
    name: "AddressInfo",

    props: {
      address: {
        type: Object,
        required: true
      },
      color: {
        type: String,
        required: false,
        default: () => "black-3"
      }
    },

    computed: {
      firstLine() {
        return [
          this.address.address,
          this.address.number,
          this.address.complement ?? null
        ]
          .filter(value => !!value)
          .join(", ");
      },
      secondLine() {
        const data = { ...this.address };

        if (data.state.uf === "DF") {
          data.city = "Brasília";
        }

        return `${data.neighborhood}, ${data.city}/${data.state.uf}, ${data.zipCode}`;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
