import { debounce, pickBy, identity } from "lodash";

export default {
  data: () => ({
    dataTableOptions: {},

    tableInfo: {
      currentPage: 1,
      itemsPerPage: 10,
      totalPages: 1,
      itemCount: null,
      totalItems: null
    },

    filters: {},

    filterPristine: true
  }),

  watch: {
    dataTableOptions: {
      handler(_newVal, oldVal) {
        if (oldVal?.sortBy && oldVal?.sortDesc) {
          this.updateData();
        }
      },
      deep: true
    }
  },

  mounted() {
    if (typeof this.updateData !== "function") {
      throw new Error(
        "Use of TableHandler mixin MUST implement updateData method"
      );
    }
  },

  created() {
    this.setFilterDebounced = debounce(async function(key, value) {
      this.filterPristine = false;
      this.filters[key] = value;
      this.setTableInfoAndUpdateData({ currentPage: 1 });
    }, 800);
  },

  methods: {
    initFilter(key, value) {
      this.filters[key] = value;
    },

    setFilter(key, value) {
      this.filterPristine = false;
      this.filters[key] = value;
      this.filters = pickBy(this.filters, identity);
      this.setTableInfoAndUpdateData({ currentPage: 1 });
    },

    clearFilter(key) {
      this.filterPristine = false;
      this.filters[key] = "";
      this.setTableInfoAndUpdateData({ currentPage: 1 });
    },

    setMultipleFilters(filters) {
      this.filterPristine = false;
      this.filters = {
        ...this.filters,
        ...filters
      };
      this.filters = pickBy(this.filters, identity);
      this.setTableInfoAndUpdateData({ currentPage: 1 });
    },

    setTableInfo(payload) {
      this.tableInfo = {
        ...this.tableInfo,
        ...payload
      };
    },

    setTableInfoAndUpdateData(payload) {
      this.setTableInfo(payload);
      this.updateData();
    },

    getSortingData() {
      const { sortBy = [], sortDesc = [] } = this.dataTableOptions;

      if (sortBy?.length && sortDesc?.length) {
        const [firstSortBy] = sortBy;
        const [firstSortDesc] = sortDesc;

        return {
          sortBy: firstSortBy,
          orderBy: firstSortDesc ? "DESC" : "ASC"
        };
      }

      return {};
    },

    getPayloadData() {
      const filters = pickBy(this.filters, identity);
      const pagination = {
        page: this.tableInfo.currentPage,
        limit: this.tableInfo.itemsPerPage
      };
      const sortingData = this.getSortingData();

      return {
        ...filters,
        ...pagination,
        ...sortingData
      };
    },

    handleCurrentPageChange(currentPage) {
      this.setTableInfoAndUpdateData({ currentPage });
    },

    handleItemsPerPageChange(itemsPerPage) {
      this.setTableInfoAndUpdateData({ itemsPerPage, currentPage: 1 });
    }
  }
};
