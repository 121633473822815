<template>
  <div>
    <v-menu transition="slide-x-transition" bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          mdi-dots-horizontal
        </v-icon>
      </template>
      <v-list>
        <v-list-item
          @click="pevProfile"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-store</v-icon
            ><span class="pl-3">Ver perfil</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="canEdit"
          @click="handleEditPev"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-pencil</v-icon
            ><span class="pl-3">Editar perfil</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <dialog-view-pev
      v-if="dialogViewPevState.dialog"
      :state="dialogViewPevState"
      :pev="pev"
    />

    <dialog-toggle-pev-situation
      v-if="dialogTogglePevSituationState.dialog"
      :state="dialogTogglePevSituationState"
      :title="situationMode.toggleSituationConfirmationTitle"
    />

    <dialog-edit-pev
      :key="`dialog-edit-pev-${dialogEditPevStateKey}`"
      :state="dialogEditPevState"
      :pev="pev"
      @submit="handleSubmitEditPev"
    />
  </div>
</template>

<script>
  import DialogViewPEV from "@/modules/partner/views/components/DialogViewPEV";
  import DialogTogglePEVSituation from "@/modules/partner/views/components/DialogTogglePEVSituation";
  import DialogEditPev from "@/modules/partner/views/components/DialogEditPev";
  import { PEVSituation } from "@/modules/partner/enum/pevSituation.enum";
  import { hasKeys } from "@/modules/core/helpers/objectHelpers";
  import { profiles } from "@/modules/core/enums/profiles.enum";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "PevListItemActions",

    props: {
      pev: {
        type: Object,
        required: true,
        validator: obj =>
          hasKeys(obj, ["name", "businessHours", "address", "photo"])
      }
    },

    components: {
      DialogViewPev: DialogViewPEV,
      DialogTogglePevSituation: DialogTogglePEVSituation,
      DialogEditPev: DialogEditPev
    },

    data: () => ({
      situationModes: {
        [PEVSituation.ACTIVE.id]: {
          toggleSituationIcon: "mdi-block-helper",
          toggleSituationLabel: "Desativar",
          toggleSituationConfirmationTitle: "Você deseja desativar a PEV?"
        },
        [PEVSituation.INACTIVE.id]: {
          toggleSituationIcon: "mdi-restore",
          toggleSituationLabel: "Ativar",
          toggleSituationConfirmationTitle: "Você deseja ativar a PEV?"
        }
      },

      dialogEditPevStateKey: 1,
      dialogEditPevState: {
        dialog: false,
        form: {
          name: undefined,
          photo: undefined,
          businessHours: undefined,
          address: {
            zipCode: undefined,
            city: undefined,
            neighborhood: undefined,
            number: undefined,
            address: undefined,
            complement: undefined,
            state: undefined
          }
        }
      },

      dialogViewPevState: {
        dialog: false
      },

      dialogTogglePevSituationState: {
        dialog: false
      },

      canEdit: false
    }),

    mounted() {
      this.initPermissions();
    },

    watch: {
      "dialogEditPevState.dialog": function(newVal) {
        if (!newVal) {
          this.dialogEditPevStateKey++;
        }
      }
    },

    computed: {
      ...mapGetters({
        user: "user/getUser"
      }),
      situationMode() {
        return this.situationModes[PEVSituation.ACTIVE.id];
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("partner", ["updatePEV"]),

      initPermissions() {
        const usersCanEdit = [
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ];

        this.canEdit = usersCanEdit.includes(this.user.role.id);
      },

      pevProfile() {
        this.dialogViewPevState.dialog = true;
      },

      handleEditPev() {
        this.dialogEditPevState = {
          dialog: true,
          form: { ...this.pev }
        };
      },

      handleTogglePevSituation() {
        this.dialogTogglePevSituationState.dialog = true;
      },

      async handleSubmitEditPev(payload) {
        this.dialogEditPevState.dialog = false;
        try {
          const editPevPayload = this.mountEditPevPayload(payload);
          const response = await this.updatePEV({
            id: this.pev.id,
            body: editPevPayload
          });
          this.toggleSnackbar({
            text: response?.data?.message ?? "PEV alterado com sucesso",
            type: "success"
          });
          this.$emit("updated");
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro: Tente novamente",
            type: "error"
          });
        }
      },

      mountEditPevPayload(payload) {
        return {
          ...payload
        };
      }
    }
  };
</script>

<style></style>
