<template>
  <div>
    <v-row v-for="(phone, index) in innerValue" :key="index">
      <v-col cols="12" md="4">
        <field
          v-model="innerValue[index].phoneType"
          type="select"
          label="Tipo de telefone"
          name="phoneType"
          :items="phoneTypes"
          item-value="value"
          item-text="label"
          :isRequired="isRequired"
        />
      </v-col>
      <v-col cols="12" md="4">
        <field
          v-model="innerValue[index].phoneNumber"
          type="tel"
          label="Número de telefone"
          name="phoneNumber"
          :isRequired="isRequired"
        />
      </v-col>
      <v-col cols="12" md="4">
        <p
          v-if="!addOrDeletePhone(index, innerValue.length)"
          @click="addDummyItem"
          class="my-md-3 text-center secondary-color-1--text pointer"
        >
          Adicionar
        </p>
        <p
          v-if="addOrDeletePhone(index, innerValue.length)"
          @click="delPhoneFromPhones(phone)"
          class="my-md-3 text-center black-4--text pointer"
        >
          Remover
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { phoneTypes } from "@/modules/core/enums/phoneTypes.enum";
  import Field from "@/modules/core/views/components/Field";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  export default {
    name: "PhoneField",

    components: {
      Field
    },

    mixins: [MasksMixin],

    props: {
      value: {
        type: Array,
        required: true
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    mounted() {
      this.innerValue = this.value.length ? this.mountInitialValues() : [{}];
    },

    watch: {
      innerValue(newVal) {
        this.$emit("input", newVal);
      },
      value(newVal) {
        this.innerValue = newVal;
      }
    },

    computed: {
      phoneTypes() {
        return Object.values(phoneTypes);
      }
    },

    data: () => ({
      innerValue: []
    }),

    methods: {
      addOrDeletePhone(index, length) {
        if (length === 0 || index === 2) {
          return true;
        }

        return length !== index + 1;
      },

      addDummyItem() {
        this.innerValue.push({});
      },

      delPhoneFromPhones(phone) {
        this.innerValue.splice(this.innerValue.indexOf(phone), 1);
      },

      mountInitialValues() {
        if (
          this.value?.length &&
          !this.value[0].phone &&
          !this.value[0].phoneNumber
        ) {
          return [{}];
        }
        return this.value.map(phone => {
          if (!phone?.phone && !phone?.phoneNumber) {
            return {};
          }
          return {
            ...phone,
            phoneNumber: phone?.phoneNumber ?? this.parsePhone(phone)
          };
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
