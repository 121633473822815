<template>
  <div>
    <!-- password -->
    <validation-provider
      v-if="type === 'password'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        :error-messages="errors[0]"
        type="password"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- weight -->
    <validation-provider
      v-if="type === 'weight'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        :error-messages="errors[0]"
        type="number"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- email -->

    <validation-provider
      v-if="type === 'email'"
      :name="name"
      :rules="{ required: isRequired, email: true }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        :error-messages="errors[0]"
        type="email"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- text -->
    <validation-provider
      v-if="type === 'genericText'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        :error-messages="errors[0]"
        type="text"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- number -->
    <validation-provider
      v-if="type === 'number'"
      :name="name"
      :rules="{
        required: isRequired,
        max_value: maxValue,
        min_value: minValue
      }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        :error-messages="customError || errors[0]"
        type="number"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- textarea -->
    <validation-provider
      v-if="type === 'textarea'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-textarea
        v-model.lazy="innerValue"
        :error-messages="errors[0]"
        type="text"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-textarea>
    </validation-provider>

    <!-- cpf -->
    <cpf-field
      v-if="type === 'cpf'"
      :label="label"
      :name="name"
      :isRequired="isRequired"
      v-model="innerValue"
    />

    <!-- CNPJ -->
    <cnpj-field
      v-if="type === 'cnpj'"
      :label="label"
      :name="name"
      :isRequired="isRequired"
      v-model="innerValue"
    />

    <!-- RG -->
    <validation-provider
      v-if="type === 'rg'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        v-mask="masks.rg"
        :error-messages="errors[0]"
        type="text"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- CNH -->
    <validation-provider
      v-if="type === 'cnh'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        v-mask="masks.cnh"
        :error-messages="errors[0]"
        type="text"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- select -->
    <validation-provider
      v-if="type === 'select'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-select
        v-model.lazy="innerValue"
        :error-messages="errors[0]"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-select>
    </validation-provider>

    <!-- telefone -->
    <validation-provider
      v-if="type === 'tel'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        v-mask="phoneMask"
        @input="inputPhone"
        :error-messages="errors[0]"
        maxlength="15"
        maxlen
        type="tel"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>

    <!-- cep -->
    <cep-field
      v-if="type === 'cep'"
      :label="label"
      :name="name"
      :isRequired="isRequired"
      :customError="customError"
      v-model="innerValue"
    />

    <!-- data -->
    <validation-provider
      v-if="type === 'calendar'"
      :name="name"
      :rules="{ required: isRequired }"
      v-slot="{ errors }"
    >
      <v-text-field
        v-model.lazy="innerValue"
        :error-messages="errors[0]"
        append-icon="mdi-calendar"
        readonly
        v-bind="{ ...$attrs, ...attrs }"
        v-on="$listeners"
      ></v-text-field>
    </validation-provider>
  </div>
</template>

<script>
  import { extend } from "vee-validate";
  import { ValidationProvider } from "vee-validate";
  import { email, max_value, min_value } from "vee-validate/dist/rules";

  import CpfField from "@/modules/core/views/components/CpfField";
  import CnpjField from "@/modules/core/views/components/CnpjField";
  import CepField from "@/modules/core/views/components/CepField";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import FieldMixin from "@/modules/core/mixins/fieldMixin";

  extend("email", {
    ...email,
    message: "E-mail inválido"
  });

  extend("max_value", {
    ...max_value,
    message: "Quantidade máxima selecionada inválida"
  });

  extend("min_value", {
    ...min_value,
    message: "Quantidade mínima selecionada inválida"
  });

  export default {
    name: "Field",

    components: {
      ValidationProvider,
      CpfField,
      CnpjField,
      CepField
    },

    mixins: [MasksMixin, FieldMixin],

    props: {
      type: {
        type: String,
        required: true
      },
      value: {
        type: null
      },
      maxValue: {
        type: Number,
        required: false
      },
      minValue: {
        type: Number,
        required: false
      },
      customError: { type: String, require: false, default: () => undefined }
    },

    mounted() {
      this.innerValue = this.value;
    },

    watch: {
      innerValue(newVal) {
        this.$emit("input", newVal || null);
      },
      value(newVal) {
        this.innerValue = newVal;
      }
    },

    data: () => ({
      innerValue: ""
    })
  };
</script>

<style lang="sass" scoped></style>
