<template>
  <div class="upload-file">
    <v-file-input
      class="container-file"
      :prepend-icon="''"
      :prepend-inner-icon="'mdi-file-outline'"
      v-if="files"
      v-model="files"
      filled
      loading
      small-chips
      show-size
      clearable
    >
      <template v-slot:selection="{ text }">
        {{ text }}
      </template>
      <template slot="progress">
        <div style="background-color: red;">
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            rounded
            height="12"
            color="secondary-color-1"
          ></v-progress-linear>
          <v-progress-linear
            v-if="!isLoading"
            color="secondary-color-1"
            rounded
            height="12"
            value="100"
          ></v-progress-linear>
        </div>
      </template>
    </v-file-input>
    <v-file-input
      v-model="files"
      v-if="!files"
      class="icon-contract"
      hide-input
      filled
      prepend-icon="mdi-archive-arrow-up"
    ></v-file-input>
    <p v-if="!files" class="contract-title">
      {{ $t.uploadFile.title }}
    </p>
    <p v-if="!files" class="contract-subtitle">
      {{ $t.uploadFile.subtitle }}
    </p>
  </div>
</template>

<script>
  import { get } from "lodash";
  import { mapActions } from "vuex";

  export default {
    name: "UploadFile",

    props: ["value"],

    data: () => ({
      innerFiles: null,
      isLoading: false
    }),

    computed: {
      files: {
        get() {
          if (!this.innerFiles && !!this.value) {
            return this.value;
          }
          return this.innerFiles;
        },
        set(newVal) {
          if (!newVal) {
            return this.removeFiles();
          }
          this.sendFile(newVal);
        }
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar", "uploadFile", "removeFile"]),

      async sendFile(files) {
        const payload = new FormData();
        payload.append("type", 1);
        payload.append("file", files);
        this.isLoading = true;
        try {
          const { data } = await this.uploadFile(payload);
          this.innerFiles = data;
          this.$emit("input", this.innerFiles);
          this.$emit("uploadFile", data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        } finally {
          this.isLoading = false;
        }
      },

      async removeFiles() {
        if (Array.isArray(this.innerFiles) || !this.innerFiles?.hash) {
          this.innerFiles = null;
          return;
        }
        try {
          await this.removeFile(this.innerFiles.hash);
        } catch (err) {
          const message = get(err, "response.data.message");
          this.toggleSnackbar({
            text: message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        } finally {
          this.innerFiles = null;
          this.$emit("input", this.innerFiles);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .upload-file {
    background: #f3f6f9;
    border: 1px dashed #a1b1b9;
    border-radius: 6px;

    .container-file {
      height: 91px;
      border: 1px solid #e8ebee;
      border-radius: 6px;
    }

    .icon-contract {
      display: block;
      margin: auto;
      width: fit-content;
      color: $black-4;
      cursor: pointer;
    }

    p {
      text-align: center;
    }

    .contract-title {
      color: $black-1;
      font-size: 16px;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .contract-subtitle {
      color: $black-4;
      font-size: 14px;
      margin-bottom: 10px;
    }

    ::v-deep .v-input__slot {
      background-color: #ffffff !important;
    }

    ::v-deep .v-input__icon {
      i {
        font-size: 30px;
        color: $white-3;
      }
    }

    ::v-deep .v-progress-linear {
      width: 87%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
