<template>
  <div>
    <v-dialog v-model="state.dialog" :persistent="true" width="588">
      <v-card>
        <div class="image-container">
          <core-image v-model="photoHash" />
        </div>

        <div class="mx-5 my-3">
          <p class="mt-3 mb-0">Nome do PEV</p>
          <p class="my-0 black-1--text">{{ pev.name }}</p>
        </div>

        <v-divider></v-divider>

        <div class="mx-5 my-3">
          <p class="mt-3 mb-0">Endereço</p>
          <p
            class="my-0 black-1--text"
            v-for="(line, index) in address"
            :key="index"
          >
            {{ line }}
          </p>
        </div>

        <v-divider></v-divider>

        <div class="mx-5 my-3">
          <p class="mt-3 mb-0">Funcionamento</p>
          <p class="my-0 black-1--text">{{ pev.businessHours }}</p>
        </div>

        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-center align-center">
          <v-btn
            class="cancel-button black-3--text"
            :block="$isMobile"
            color="#FFF"
            @click="state.dialog = false"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import CoreImage from "@/modules/core/views/components/CoreImage";
  import { hasKeys } from "@/modules/core/helpers/objectHelpers";
  import { parseAddress } from "@/modules/core/helpers/addressHelpers";

  export default {
    name: "DialogViewPEV",

    props: {
      state: {
        type: Object,
        required: true,
        validator: obj => hasKeys(obj, ["dialog"])
      },
      pev: {
        type: Object,
        required: true,
        validator: obj =>
          hasKeys(obj, ["name", "businessHours", "address", "photo"])
      }
    },

    components: {
      CoreImage
    },

    computed: {
      address() {
        return parseAddress(this.pev.address);
      },

      photoHash() {
        return this.pev.photo?.hash ?? null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .image-container {
    width: 100%;
    height: 216px;
    overflow: hidden;
  }
</style>
