<template>
  <v-card>
    <v-card-title>
      <h3>PEV - Ponto de Entrega Voluntaria</h3>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <pev-list v-if="hasPEVs" :height="400" class="my-5">
        <pev-list-item
          v-for="(pev, index) in PEVs"
          :key="`pev-item-${index}`"
          :canRemove="true"
          @remove="() => {}"
          :pev="pev"
        >
          <template v-slot:action="{ pev }">
            <pev-list-item-actions :pev="pev" @updated="$emit('updated')" />
          </template>
        </pev-list-item>
      </pev-list>

      <pev-not-found v-if="!hasPEVs && !isLoading" class="mt-7" />

      <v-row>
        <v-col cols="4" md="3">
          <btn-pdf-download @click="handleGeneratePdf" />
        </v-col>
        <v-col cols="8" md="9">
          <btn-dashed
            v-if="canAddPEV"
            depressed
            block
            title="Adicionar PEV"
            :btnAction="openAddPevDialog"
          ></btn-dashed>
        </v-col>
      </v-row>
    </v-card-text>
    <add-pev
      :state="addPevDialogState"
      @submit="handleAddPev"
      :key="`add-pev-${addPevDialogKey}`"
    />
    <pev-report
      v-if="partner && PEVs"
      @generatedReport="generateReport = false"
      :partner="partner"
      :pevs="PEVs"
      :generate="generateReport"
    />
  </v-card>
</template>

<script>
  import BtnDashed from "@/modules/core/views/components/BtnDashed";
  import BtnPdfDownload from "@/modules/core/views/components/BtnPdfDownload";
  import AddPev from "@/modules/partner/views/components/AddPev";
  import PevList from "@/modules/partner/views/components/PevList";
  import PevListItem from "@/modules/partner/views/components/PevListItem";
  import PevListItemActions from "@/modules/partner/views/components/PevListItemActions";
  import PEVReport from "@/modules/core/views/components/PEVReport";
  import PEVNotFound from "@/modules/partner/views/components/PEVNotFound";
  import { profiles } from "@/modules/core/enums/profiles.enum";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "PartnerPEVs",

    components: {
      BtnDashed,
      BtnPdfDownload,
      AddPev,
      PevList,
      PevListItem,
      PevNotFound: PEVNotFound,
      PevListItemActions,
      PevReport: PEVReport
    },

    data: () => ({
      addPevDialogKey: 1,
      addPevDialogState: {
        dialog: false
      },

      generateReport: false,

      canAddPEV: false
    }),

    mounted() {
      this.initPermissions();
    },

    watch: {
      "addPevDialogState.dialog": function(newVal) {
        if (!newVal) {
          this.addPevDialogKey++;
        }
      }
    },

    computed: {
      ...mapGetters({
        hasPEVs: "partner/getHasPartnerVoluntaryDeliveryPoints",
        PEVs: "partner/getPartnerVoluntaryDeliveryPoints",
        partner: "partner/getPartnerView",
        user: "user/getUser",
        isLoading: "isLoading"
      })
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("partner", ["createPEV"]),

      initPermissions() {
        const usersCanAddPEV = [
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id,
          profiles.RECEPTIONIST.id
        ];

        this.canAddPEV = usersCanAddPEV.includes(this.user.role.id);
      },

      openAddPevDialog() {
        this.addPevDialogState.dialog = true;
      },

      async handleAddPev(payload) {
        this.addPevDialogState.dialog = false;
        try {
          const createPEVPayload = {
            body: this.mountCreatePEVPayload(payload)
          };
          const response = await this.createPEV(createPEVPayload);

          this.toggleSnackbar({
            text:
              response?.data?.message ??
              "Ponto de Entrega Voluntária registrada com sucesso",
            type: "success"
          });
          this.$emit("updated");
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      mountCreatePEVPayload(payload) {
        return {
          ...payload,
          partner: {
            id: this.partner.id
          }
        };
      },

      handleGeneratePdf() {
        this.generateReport = true;
      }
    }
  };
</script>

<style></style>
