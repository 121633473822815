<template>
  <div>
    <v-dialog v-model="state.dialog" width="488" :persistent="true">
      <validation-observer>
        <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
          <v-card>
            <v-card-title>Alterar Representante</v-card-title>
            <div class="mt-4 mx-5">
              <v-row>
                <v-col cols="12">
                  <field
                    v-model="state.form.name"
                    type="genericText"
                    label="Nome completo"
                    name="name"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <field
                    v-model="state.form.email"
                    type="email"
                    label="E-mail"
                    name="email"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>

              <phone-field v-model="state.form.phones" :isRequired="true" />
            </div>
            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                class="mt-1 cancel-button black-3--text"
                color="#ffffff"
                :block="$isMobile"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid"
                :block="$isMobile"
                color="secondary-color-1"
                class="ma-0 mt-1 next-step-button white--text"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import Field from "@/modules/core/views/components/Field";
  import PhoneField from "@/modules/core/views/components/PhoneField";

  export default {
    name: "DialogEditPartnerAgent",

    props: {
      state: {
        type: Object,
        required: true
      }
    },

    components: {
      ValidationObserver,
      Field,
      PhoneField
    },

    methods: {
      submitForm() {
        this.$emit("submit", { ...this.state.form });
        this.state.dialog = false;
      }
    }
  };
</script>

<style></style>
