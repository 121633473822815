<template>
  <v-chip
    :color="chipColor[text].color"
    :text-color="chipColor[text].textColor"
  >
    {{ text }}
  </v-chip>
</template>

<script>
  import { PartnerSituation } from "@/modules/partner/enum/partnerSituation.enum";
  export default {
    name: "PartnerSituationChip",

    props: {
      text: {
        required: true,
        type: String,
        validator: function(value) {
          return Object.values(PartnerSituation).includes(value);
        }
      }
    },

    data: () => ({
      chipColor: {
        [PartnerSituation.ACTIVE]: {
          color: "situation-2",
          textColor: "white"
        },
        [PartnerSituation.INACTIVE]: {
          color: "situation-3",
          textColor: "white"
        }
      }
    })
  };
</script>

<style lang="scss" scoped></style>
