<template>
  <data-not-found
    :title="['Ainda não existem PEVs cadastrados.', 'Adicione um PEV.']"
    :imgSrc="require('@/assets/pev.svg')"
  />
</template>

<script>
  import DataNotFound from "@/modules/core/views/components/DataNotFound";

  export default {
    name: "PEVNotFound",

    components: {
      DataNotFound
    }
  };
</script>

<style></style>
