<template>
  <div>
    <v-dialog v-model="state.dialog" :persistent="true" width="488">
      <validation-observer>
        <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
          <v-card>
            <v-card-title>Novo Contrato</v-card-title>
            <div class="mt-4 mx-5">
              <v-row class="contract-container">
                <v-col cols="12">
                  <upload-file
                    :value="form.contractFile"
                    @uploadFile="updateFile"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="6">
                  <date-field
                    v-model="form.beginAt"
                    label="Data Início"
                    :isRequired="true"
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <date-field
                    v-model="form.endAt"
                    label="Data Termino"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
            </div>
            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                class="mt-1 cancel-button black-3--text"
                color="#ffffff"
                :block="$isMobile"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid || !form.file.id"
                :block="$isMobile"
                color="secondary-color-1"
                class="ma-0 mt-1 next-step-button white--text"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { hasKeys } from "@/modules/core/helpers/objectHelpers";
  import DateField from "@/modules/core/views/components/DateField";
  import UploadFile from "@/modules/core/views/components/UploadFile";

  export default {
    name: "DialogNewContract",

    props: {
      state: {
        type: Object,
        required: true,
        validator: obj => hasKeys(obj, ["dialog"])
      }
    },

    data: () => ({
      form: {
        beginAt: null,
        endAt: null,
        file: {},
        contractFile: null
      }
    }),

    components: {
      ValidationObserver,
      DateField,
      UploadFile
    },

    methods: {
      updateFile(file) {
        this.form = {
          ...this.form,
          file: { ...file }
        };
      },
      submitForm() {
        this.$emit("submit", {
          ...this.form
        });
        this.state.dialog = false;
      }
    }
  };
</script>

<style></style>
