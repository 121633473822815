<template>
  <div class="d-flex flex-column fill" :style="styles">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "PevList",

    props: {
      height: {
        type: Number,
        required: false,
        default: () => 600
      }
    },

    computed: {
      styles() {
        return {
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: `${this.height}px`,
          height: `${this.height}px`
        };
      }
    }
  };
</script>

<style></style>
