export function preparePhonesToSubmit(phones) {
  if (!phones || !phones.length) {
    return [];
  }
  if (Object.values(phones[0]).length === 0) {
    return [];
  }
  return phones.map(phone => ({
    ...phone,
    phone: phone.phoneNumber
      ? phone.phoneNumber
          .split(" ")[1]
          .split("-")
          .join("")
      : null,
    areaCode: phone.phoneNumber
      ? phone.phoneNumber.split(" ")[0].slice(1, 3)
      : null
  }));
}
