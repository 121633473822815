<template>
  <v-container>
    <title-header></title-header>

    <v-row>
      <v-col cols="12" md="5" lg="3">
        <partner-info @updated="updateData" />
      </v-col>
      <v-col cols="12" md="7" lg="5">
        <partner-pevs @updated="updateData" />
      </v-col>
      <v-col cols="12" md="12" lg="4">
        <partner-contracts @updated="updateData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import PartnerInfo from "@/modules/partner/views/components/PartnerInfo";
  import PartnerPEVs from "@/modules/partner/views/components/PartnerPEVs";
  import PartnerContracts from "@/modules/partner/views/components/PartnerContracts";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { parseISO } from "date-fns";
  import { mapActions } from "vuex";

  import store from "@/store";

  export default {
    name: "ViewPartner",

    beforeRouteLeave: (to, from, next) => {
      store.dispatch("partner/resetPartnerView");
      next();
    },

    components: {
      TitleHeader,
      PartnerInfo,
      PartnerPevs: PartnerPEVs,
      PartnerContracts
    },

    mixins: [MasksMixin],

    data: () => ({
      partnerId: null
    }),

    async mounted() {
      this.partnerId = parseInt(this.$route.params.id, 10);
      await this.updateData();
    },

    methods: {
      ...mapActions("partner", ["getPartnerById", "setPartnerView"]),
      ...mapActions(["toggleSnackbar"]),

      async updateData() {
        const partner = await this.fetchPartner(this.partnerId);
        this.setPartnerView(partner);
      },

      async fetchPartner(id) {
        try {
          const { data } = await this.getPartnerById(id);

          return this.formatPartnerData(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatPartnerData(data) {
        return {
          ...data,
          agent: {
            ...data.agent,
            phonesNumber: data.agent?.phones
              .map(phone => this.parsePhone(phone))
              .join(", ")
          },
          lastContract: this.formatContract(data.lastContract)
        };
      },

      formatContract(contract) {
        const beginAtISOFormat = parseISO(contract.beginAt);
        const endAtISOFormat = parseISO(contract.endAt);

        return {
          ...contract,
          beginAt: this.parseDate(beginAtISOFormat, "dd MMM yyyy"),
          endAt: this.parseDate(endAtISOFormat, "dd MMM yyyy")
        };
      }
    }
  };
</script>

<style></style>
