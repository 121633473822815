<template>
  <div class="d-flex justify-center">
    <div v-if="dataUrlImage" class="img-edit-container">
      <div class="img-edit-button" @click="handleContainerClick">
        <v-icon>mdi-pencil</v-icon>
      </div>
    </div>

    <div
      class="container d-flex align-center justify-center"
      @click="handleContainerClick"
    >
      <v-file-input
        style="display: none;"
        ref="fileInput"
        hide-input
        @change="handleChange"
      ></v-file-input>

      <div
        class="img-container d-flex justify-center align-center"
        v-if="dataUrlImage"
      >
        <img :src="dataUrlImage" alt="" />
      </div>

      <div v-else class="d-flex flex-column justify-center align-center">
        <v-icon color="black-4">mdi-camera</v-icon>
        <p class="ma-0 img-upload-title">
          Adicionar Foto
        </p>
        <p class="ma-0 img-upload-subtitle">
          Formatos JPG, PNG
        </p>
      </div>

      <div class="img-loading-overlay" v-if="isLoading">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce } from "lodash";
  import { blobToDataUrl } from "@/modules/core/helpers/fileHelpers";

  import { mapActions } from "vuex";

  export default {
    name: "ImageUpload",

    props: {
      hash: {
        type: String,
        required: false
      }
    },

    data: () => ({
      isLoading: false,

      file: undefined,
      dataUrlImage: undefined
    }),

    mounted() {
      this.loadInitialImage();
    },

    methods: {
      ...mapActions(["toggleSnackbar", "uploadFile", "getBlobFile"]),

      async handleChange($event) {
        if (this.isLoading || !$event) {
          return;
        }
        this.isLoading = true;
        try {
          this.dataUrlImage = await blobToDataUrl($event);

          await this.sendImage($event);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        } finally {
          this.isLoading = false;
        }
      },

      async loadInitialImage() {
        if (!this.hash) {
          return;
        }

        try {
          const blob = await this.getBlobFile(this.hash);
          this.dataUrlImage = await blobToDataUrl(blob);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      async sendImage(fileImage) {
        try {
          const payload = this.mountPayload(fileImage);
          const { data } = await this.uploadFile(payload);
          this.$emit("onUploadComplete", data);
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },

      mountPayload(fileImage) {
        const payload = new FormData();

        payload.append("type", 1);
        payload.append("file", fileImage);

        return payload;
      },

      handleContainerClick() {
        const inputRef = this.$refs.fileInput;
        const innerInputRef = inputRef.$refs.input;
        innerInputRef.click();
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .container {
    width: 152px;
    height: 172px;
    border: 1px dashed #a1b1b9;
    background-color: #f3f6f9;
    border-radius: 6px;
    padding: 0 1px;
    cursor: pointer;
  }

  .img-upload-title {
    font-size: 16px;
    color: #000000de;
    font-weight: 700;
  }

  .img-upload-subtitle {
    color: #90a4ae;
    font-size: 14px;
  }

  .img-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    img {
      height: 100%;
    }
  }

  .img-loading-overlay {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 3;
    background-color: rgba(20, 15, 15, 0.4);
  }

  .img-edit-container {
    position: absolute;
    width: 42px;
    height: 42px;
    z-index: 4;
  }

  .img-edit-button {
    position: relative;
    top: -16px;
    right: -70px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
    border-radius: 100%;
    background-color: $white-2;
    cursor: pointer;
    border: 1px solid $black-3;

    &:hover {
      border: 1px solid $black-2;
      .v-icon {
        color: $primary-color-2;
      }
    }
  }
</style>
