export const PEVSituation = {
  INACTIVE: {
    id: 1,
    description: "Inativo"
  },
  ACTIVE: {
    id: 2,
    description: "Ativo"
  }
};
