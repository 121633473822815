<template>
  <v-btn
    height="56"
    color="white"
    v-bind="{ ...$attrs }"
    class="btn-dashed primary-color-2--text"
    depressed
    @click="btnAction"
  >
    {{ title }}</v-btn
  >
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      btnAction: {
        type: Function,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .btn-dashed {
    border: 1px dashed $primary-color-2 !important;
  }
</style>
