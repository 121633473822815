<template>
  <v-menu
    v-model="beginAtMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <field
        v-model="displayDate"
        type="calendar"
        :label="label"
        name="displayDate"
        :isRequired="isRequired"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      ref="picker"
      locale="pt-BR"
      v-bind="$attrs"
      color="orange darken-2"
      header-color="orange darken-2"
      v-model.lazy="computedInnerValue"
      @input="beginAtMenu = false"
      @change="beginAtChange"
    ></v-date-picker>
  </v-menu>
</template>

<script>
  import Field from "@/modules/core/views/components/Field";

  export default {
    name: "DateField",

    props: {
      value: {
        type: null
      },
      label: {
        type: String,
        required: false,
        default: () => "Data"
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: () => false
      }
    },

    data: () => ({
      beginAtMenu: false,
      innerValue: ""
    }),

    watch: {
      beginAtMenu(val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      },
      value(newVal) {
        if (newVal.indexOf("/") === 2) {
          this.innerValue = newVal
            .split("/")
            .reverse()
            .join("-");
        }
        if (newVal) {
          this.innerValue = newVal;
        }
      }
    },

    computed: {
      displayDate: {
        get() {
          if (this.computedInnerValue) {
            return this.computedInnerValue
              .split("-")
              .reverse()
              .join("/");
          }
          return null;
        },
        set(value) {
          return value;
        }
      },
      computedInnerValue: {
        get() {
          if (!this.innerValue && this.value) {
            return this.value;
          }
          return this.innerValue;
        },
        set(newVal) {
          this.innerValue = newVal;
          this.$emit("input", this.innerValue);
        }
      }
    },

    components: {
      Field
    },

    mounted() {
      if (this.value) {
        this.innerValue = this.value;
      }
    },

    methods: {
      beginAtChange(val) {
        if (val) {
          this.innerValue = val;
        }
      }
    }
  };
</script>

<style></style>
