import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["getBlobFile", "toggleSnackbar"]),

    async executeDownload(hash) {
      try {
        const blob = await this.getBlobFile(hash);
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } catch (error) {
        this.toggleSnackbar({
          text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
          type: "error"
        });
      }
    }
  }
};
