<template>
  <div style="position: fixed; bottom: 10000px">
    <!-- Esconde o conteúdo na tela, mas continuar mostrando para os leitores de tela. -->
    <div ref="generateReport" class="background-report">
      <card class="card-report">
        <template v-slot:table>
          <div class="logo-pf">
            <img src="@/assets/Logo_PF.png" alt="" />
          </div>
          <p class="title-report">Relação dos PEVs por Parceiro</p>
          <div class="body-report">
            <v-row>
              <v-col class="container-subtitle" cols="2">
                <span class="subtitle-report">Parceiro</span>
              </v-col>
              <v-col class="container-subtitle" cols="10">
                <hr />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <div>
                  <span class="partner-label"> {{ partner.companyName }} </span
                  ><br />
                  <span class="cnpj-value" v-if="partner.cnpj">
                    {{ partner.cnpj | VMask(masks.cnpj) }}
                  </span>
                  <br />
                  <v-chip
                    :color="!partner.removedAt ? 'success' : 'error'"
                    class="flag-status"
                  >
                    <span class="white--text caption">{{
                      !partner.removedAt ? "Ativado" : "Bloqueado"
                    }}</span>
                  </v-chip>
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <div class="partner-field">
                    <span class="partner-label"> CRC </span><br />
                    <span
                      v-if="lastContract && lastContract.crc"
                      class="patner-value"
                      ><v-icon class="icon-report">mdi-store</v-icon>
                      {{ lastContract.crc.description }}
                    </span>
                  </div>
                  <div class="partner-field">
                    <span class="partner-label"> Contrato </span><br />
                    <span class="patner-value"
                      ><v-icon class="icon-report">mdi-calendar</v-icon> Inicio:
                      {{ beginContract }}</span
                    >
                    <span class="patner-value"
                      ><v-icon class="icon-report">mdi-calendar</v-icon>
                      Término: {{ endContract }}</span
                    >
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <span class="partner-label"> Responsável </span>
                  <span class="patner-value"
                    ><v-icon class="icon-report">mdi-account</v-icon>
                    {{ partner.agent.name }}
                  </span>
                  <span class="patner-value"
                    ><v-icon class="icon-report">mdi-email</v-icon>
                    {{ partner.agent.email }}
                  </span>
                  <span class="patner-value"
                    ><v-icon class="icon-report">mdi-phone</v-icon>
                    {{ getPartnerPhone }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="container-subtitle" cols="2">
                <span class="subtitle-report">PEVs</span>
              </v-col>
              <v-col class="container-subtitle" cols="10">
                <hr />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <div>
                  <span class="pev-label">Imagem</span>
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <span class="pev-label">Endereço</span>
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <span class="pev-label">Funcionamento</span>
                </div>
              </v-col>
            </v-row>
            <v-row v-for="(pev, i) in pevs" :key="i">
              <hr v-if="i !== 0" class="pev-line" />
              <v-col cols="4">
                <div>
                  <core-image class="pev-img" :value="getPhotoHash(pev)" />
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <span class="pev-value">{{ pev.name }}</span>
                  <span class="pev-value">{{ address(pev.address)[0] }}</span>
                  <span class="pev-value"
                    >{{ address(pev.address)[1] }},
                    {{ pev.address.zipCode | VMask(masks.zipCode) }}</span
                  >
                </div>
              </v-col>
              <v-col cols="4">
                <div>
                  <span class="pev-value">{{ pev.businessHours }}</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
  import jsPDF from "jspdf";
  import { mapGetters, mapActions } from "vuex";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Card from "@/modules/core/views/templates/CardLayout";
  import CoreImage from "@/modules/core/views/components/CoreImage";

  export default {
    name: "PEVReport",

    mixins: [MasksMixin],

    components: {
      Card,
      CoreImage
    },

    props: {
      partner: {
        type: Object,
        required: false,
        default: () => {}
      },
      pevs: {
        type: Array,
        required: false,
        default: () => []
      },
      generate: {
        type: Boolean,
        required: true
      }
    },

    data: () => ({
      output: null
    }),

    computed: {
      ...mapGetters({
        lastContract: "partner/getLastPartnerContract"
      }),
      beginContract() {
        return this.lastContract?.beginAt;
      },
      endContract() {
        return this.lastContract?.endAt;
      },
      getPartnerPhone() {
        const phone = this.partner?.agent?.phones[0];
        return phone ? this.parsePhone(phone) : "Não informado";
      }
    },

    watch: {
      generate(newVal) {
        if (newVal) {
          this.generateReport();
        }
      }
    },

    methods: {
      ...mapActions(["setLoading"]),

      getPhotoHash(pev) {
        return pev.photo?.hash ?? null;
      },

      address(address) {
        return [
          `${address.address} N° ${address.number}`,
          `${address.neighborhood} - ${address.city}/${address.state.uf}`
        ];
      },
      async generateReport() {
        try {
          this.setLoading(true);
          const doc = new jsPDF();
          const el = this.$refs.generateReport;
          const options = {
            type: "dataURL"
          };
          this.output = await this.$html2canvas(el, options);
          doc.addImage(this.output, 0, 0, 210, 300);
          doc.save(`Relação-dos-PEVs-por-parceiro.pdf`);
          this.$emit("generatedReport");
          this.setLoading(false);
        } catch (error) {
          this.toggleSnackbar({
            text: "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        } finally {
          this.setLoading(false);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .background-report {
    width: 100vw;
    max-width: 1400px;
    padding: 80px 50px;
    background-image: url("../../../../assets/back-pev-report.jpg");
    background-clip: border-box;
    background-repeat: no-repeat;
    background-size: cover;

    ::v-deep .page-title {
      display: none;
    }

    .card-report {
      min-height: 1800px;
    }

    .logo-pf > img {
      height: 100px;
      display: block;
      margin: auto;
    }

    .title-report {
      text-align: center;
      color: $primary-color-2;
      font-weight: 500;
      font-size: 35px;
      margin-top: 30px;
    }

    .body-report {
      padding: 25px 40px;

      .container-subtitle {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        .subtitle-report {
          color: $primary-color-2;
          font-weight: 500;
          font-size: 30px;
          display: block;
          margin: auto;
          margin-left: 0px;
        }

        hr {
          width: 100%;
          background-color: $white-2;
          height: 1px;
          border: none;
        }
      }

      .partner-label {
        font-size: 25px;
        color: $black-1;
        font-weight: 500;
      }

      .cnpj-value {
        font-size: 20px;
        font-weight: 500;
        color: $black-4;
      }

      .flag-status {
        background-color: $success;
        margin-top: 10px;

        span {
          color: #fff;
          font-weight: 500;
          font-size: 17px;
        }
      }

      .partner-field {
        margin-bottom: 15px;
      }

      .patner-value {
        font-size: 18px;
        font-weight: 400;
        color: $black-4;
        display: block;
        margin-bottom: 10px;
      }

      .icon-report {
        color: $black-4;
        margin-right: 10px;
      }

      .pev-label {
        font-size: 26px;
        color: $black-1;
        font-weight: bold;
      }

      .pev-img {
        height: 150px;
        width: 250px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .pev-value {
        font-size: 20px;
        font-weight: 500;
        color: $black-4;
        display: block;
        margin-bottom: 8px;
      }

      .pev-line {
        background-color: $white-2;
        height: 1px;
        border: none;
        margin: 2.5px 0px 8px 0px;
        width: 100%;
      }
    }
  }
</style>
