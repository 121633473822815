<template>
  <div
    class="d-flex pev-list-item justify-space-between mb-3"
    :class="{ 'desktop-content': !$isMobile, 'mobile-content': $isMobile }"
  >
    <div class="d-flex flex-wrap align-center">
      <div class="d-flex justify-center fill-height">
        <div class="image-container ">
          <core-image v-model="photo" class="pev-list-item-image" />
        </div>
      </div>

      <div class="ml-2 info-container">
        <p class="ma-0 mt-1 black-1--text font-weight-medium">{{ pev.name }}</p>

        <address-info :address="pev.address" />
      </div>
    </div>
    <div class="align-self-center justify-self-center" style="min-width: 40px;">
      <slot name="action" v-bind:pev="pev"></slot>
    </div>
  </div>
</template>

<script>
  import CoreImage from "@/modules/core/views/components/CoreImage";
  import AddressInfo from "@/modules/core/views/components/AddressInfo";

  export default {
    name: "PevListItem",

    props: {
      pev: {
        type: Object,
        required: true
      }
    },

    components: {
      CoreImage,
      AddressInfo
    },

    computed: {
      photo() {
        return this.pev.photo?.hash ?? null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .desktop-content {
    max-height: 100px;
  }

  .mobile-content {
    max-height: 160px;
  }

  .pev-list-item {
    border-radius: 6px;

    &:hover {
      background-color: $white-1;
    }

    .image-container {
      max-width: 102px;
      height: 100%;
    }
  }

  .info-container {
    max-width: calc(100% - 120px);
  }

  .pev-list-item-image {
    border-radius: 6px 0 0 6px;
    height: 100%;
  }
</style>
